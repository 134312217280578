<template>
  <div>
    <span class="font-base mr-10"
      >{{ selectedOrders.length }}
      {{ formatServiceUnit("order", selectedOrders.length) }} selected</span
    >
    <el-dropdown :placement="'bottom'" @command="command">
      <el-button type="primary">
        Actions <i class="eden-icon-arrow-down"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(value, key, i) in actions"
          :command="key"
          :key="i"
          :disabled="!markable[key]"
          >{{ value }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <eden-confirm-dialog
      ref="confirm"
      :show.sync="showMarkConfirmation"
      :title="actions[markFunction]"
      :button-text="'Mark'"
      :button-type="'success'"
      :button-status.sync="marking"
      @confirm="markOrders"
    >
      <p class="font-sm">
        Proceed to mark selected orders as
        <span class="text-bold text-grey-primary">{{
          formatToTitleCase(markFunction.replaceAll("_", " "))
        }}</span
        >?
      </p>
    </eden-confirm-dialog>
  </div>
</template>
<script>
import schedule from "@/requests/orders/schedule";

export default {
  name: "LaundryDropoffActions",
  props: {
    selectedOrders: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      actions: {
        received_from_sp: "Mark Received from SP",
        delivery_qc: "Mark Quality Check",
        dispatched: "Mark Rider Dispatched",
        delivery_rider_arrived: "Mark Rider Arrival",
        delivery_rider_delivered: "Mark Delivered to Customer",
      },
      markable: {
        sent_to_sp: true,
        received_from_sp: true,
        delivery_qc: true,
        dispatched: true,
        delivery_rider_arrived: true,
        delivery_rider_delivered: true,
      },
      selectedOrdersIds: [],
      markFunction: "",
      marking: false,
      showMarkConfirmation: false,
    };
  },
  watch: {
    selectedOrders() {
      this.setSelectedOrders();
    },
  },
  created() {
    this.setSelectedOrders();
  },
  methods: {
    command(command) {
      this.markFunction = command;
      this.showMarkConfirmation = true;
    },
    setSelectedOrders() {
      const markableEvents = Object.keys(this.markable);
      markableEvents.forEach((key) => {
        this.markable[key] = true;
      });

      this.selectedOrders.forEach((order) => {
        markableEvents.forEach((key, index) => {
          const previousEvent =
            index < 1
              ? order.sent_to_sp.status
              : order[markableEvents[index - 1]].status;
          const currentEvent = !order[key].status;

          this.markable[key] =
            previousEvent && currentEvent && this.markable[key];
        });
        this.selectedOrdersIds.push(order.order_id);
      });
    },
    markOrder() {
      if (!this.markable[this.markFunction]) {
        this.$message.info("You need to mark previous event.");
        this.$emit("error");
        return;
      }
      this.markOrders();
    },
    markOrders() {
      this.marking = true;
      const { logged_in_userId } = this.$store.getters.user;
      const payload = {
        actor_id: logged_in_userId,
        order_ids: this.selectedOrdersIds,
        order_status: this.markFunction,
        event: "delivery",
      };

      schedule
        .activity("laundry", payload)
        .then((response) => {
          const { status, message } = response.data;
          if (status) {
            this.showMarkConfirmation = false;
            this.$message.success(message);
            this.marking = false;
            this.$emit("updated");
          } else {
            this.$message.error(message);
            this.$emit("error");
          }
        })
        .catch((error) => {
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
          this.$emit("error");
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
